<template>
  <el-dialog
      :title="title"
      :visible.sync="createDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-drag-dialog
      @close="close">
    <div class="pageContainer">
      <el-form label-position="right"
               :model="formData"
               :rules="rules"
               ref="supplierForm"
               label-width="90px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商名称" prop="name">
              <el-input v-model="formData.name" placeholder="请输入供应商名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="序号" prop="orderNo">
              <el-input v-model.number="formData.orderNo" placeholder="请输入序号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商分类" prop="type">
              <el-select v-model="formData.type" style="width: 100%">
                <el-option v-for="item in SUPPLIER_TYPE" :value="item.key" :label="item.label" :key="item.key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面" prop="cover">
              <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadSuccess"></file-upload-btn>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商地址" prop="location">
              <el-input v-model="formData.location" placeholder="请输入供应商地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签" prop="tag">
              <el-input v-model="formData.tag" placeholder="多个标签用逗号隔开"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商风格" prop="style">
              <el-input v-model="formData.style" placeholder="请输入风格"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="供应商描述" prop="description">
            <el-input v-model="formData.description" :rows="15" style="height: 320px;" type="textarea" placeholder="请输入与供应商描述"></el-input>
<!--            <wang-editor v-if="createDialogVisible" v-model="formData.description"></wang-editor>-->
          </el-form-item>
        </el-row>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
// import WangEditor from 'modules/components/WangEditor/index.vue'
import { taskConfig as CK_TASK_CONFIG } from 'config/ckEditorConfig'
import { rules, SUPPLIER_TYPE } from '../option'

function resetSupplier() {
  return {
    name: '',
    location: '',
    cover: '',
    type: '',
    tag: '',
    style: '',
    description: '',
    orderNo: ''
  }
}

export default {
  name: 'addOrEditSupplier',
  components: {
    FileUploadBtn
    // WangEditor
  },
  data() {
    return {
      SUPPLIER_TYPE,
      optType: 'add',
      createDialogVisible: false,
      callback: null,
      formData: resetSupplier(),
      rules,
      CK_TASK_CONFIG,
      addingLoading: false
    }
  },
  computed: {
    title() {
      return this.optType === 'add' ? '新增供应商' : '编辑供应商'
    }
  },
  methods: {
    ...mapActions(['saveSupplier', 'getSupplierById', 'updateSupplier']),
    show(options) {
      this.optType = options.optType
      this.callback = options.callback
      if (this.optType === 'edit') {
        this.tryGetSupplierById(options.detail.id)
        // this.formData = options.detail
      } else {
        this.formData = resetSupplier()
      }
      this.createDialogVisible = true
      this.$nextTick(() => {
        this.$refs.supplierForm.clearValidate()
      })
    },
    close() {
      this.createDialogVisible = false
    },
    handleUploadSuccess(url) {
      this.formData.cover = url
    },
    tryGetSupplierById(id) {
      this.getSupplierById(id).then(res => {
        this.formData = res || this.formData
      })
    },
    tryUpdateSupplier() {
      this.addingLoading = true
      this.updateSupplier(this.formData).then(() => {
        this.$message.success('操作成功')
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    trySubmit() {
      if (this.addingLoading) {
        return
      }
      this.$refs.supplierForm.validate(valid => {
        if(valid) {
          const api = this.optType === 'add' ? this.trySaveSupplier : this.tryUpdateSupplier
          api()
        }
      })
    },
    trySaveSupplier() {
      this.addingLoading = true
      this.saveSupplier(this.formData).then(() => {
        this.$message.success('提交成功')
        this.resetSupplier()
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    resetSupplier() {
      this.formData = resetSupplier()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../scss/elementVar";

.pageContainer {
  padding: 20px;
}
.tagWrap {
  margin-bottom: 20px;
}
.uploadBtn {
  padding: 30px 0;
  width: 150px;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  color: $--color-info;
  border: 2px dashed $--color-info;
  opacity: 0.7;
  border-radius: 4px;
  transition: all linear 100ms;
}
.uploadBtn:hover {
  opacity: 1;
}
.uploadBtnIcon {
  font-weight: bold;
  font-size: 30px;
}
.uploadBtnText {
  line-height: 10px;
  font-size: 14px;
}
.imgContainer {
  float: left;
  margin: 0 10px 10px 0;
}
</style>
